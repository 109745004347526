import React, { Component } from 'react';
import './App.scss';
import { Router, Route } from 'react-router-dom';
import Navbar from './components/Nav/Nav';
import Header from './components/Header/Header';
import Home from './contents/Home';
import About from './contents/About';
import Sandbox from './contents/Sandbox';
import Contact from './contents/Contact';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

class App extends Component{
  componentDidMount() {
		ReactGA.pageview(window.location.pathname)
	}
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <div className="wrapper">
            <Header/>
            <div className="inner">
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/sandbox">
              <Sandbox />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            </div>
            <Navbar />
          </div>
          
          
        </div>
      </Router>
    );
  }
}

export default App;

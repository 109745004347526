import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './Navitem.scss';

class Navitem extends Component {
    render() {
        return (
            <li className="navbar__items" id={this.props.item}>
                <NavLink className="navbar__itemsLink" exact activeClassName="navbar__itemsLink--active" to={this.props.tolink} title={this.props.item}>
                    <span className="navbar__itemsLink-icon"><i className="material-icons large">{this.props.icon}</i></span>
                    <span className="navbar__itemsLink-text">{this.props.item}</span>
                </NavLink>
            </li>
        )
    }
}

export default Navitem
import React, { Component } from 'react';
import projectsData from './projects.json';
import ProjectTile from './../components/ProjectTile/ProjectTile';
import './../styles/Sandbox.scss';

class Sandbox extends Component {
    componentDidMount(){
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].className += " wrapper--sandbox";
        document.title = 'Sandbox - albertobonora.com';
    }
    componentWillUnmount() {
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].classList.remove('wrapper--sandbox');
    }
    render() {
        return (
            <div className="condiv sandbox">
                <h1 className="condiv-title">Sandbox</h1>
                <p className="condiv-text">This is my sandbox where I like to display the little projects I've put together or things that I've started to play around with and test. Right now there are only a few projects that I can display but over time I'd like to extend this to include much more. As my hobbies do include Photography, Grapic Design and more I may break this out into different sections. For now hope you view some of these and would like to hear your feedback.</p>
                <div className="sandbox-wrapper">
                    {projectsData.projects.map( (project, index) => (
                        <ProjectTile details={project} key={index}/>
                    ))}
                </div>
        
                
            </div>
        )
    }
}

export default Sandbox
import React from 'react';
import {IoLogoTwitter, IoLogoGithub, IoLogoLinkedin} from 'react-icons/io';
import './Social.scss';

const SocialLinks = () => {
    return(
        <div className="socialLinks">
            <ul className="socialLinks--list">
                <li className="socialLinks--item socialLinks--item__twitter"><a className="socialLinks--item-link" href="https://www.twitter.com/abonora" title="Twitter" target="_blank" rel="noopener noreferrer"><IoLogoTwitter/></a></li>
                <li className="socialLinks--item socialLinks--item__github"><a className="socialLinks--item-link" href="https://github.com/abonora" title="GitHub" target="_blank" rel="noopener noreferrer"><IoLogoGithub/></a></li>
                <li className="socialLinks--item socialLinks--item__linkedin"><a className="socialLinks--item-link" href="https://www.linkedin.com/in/abonora" title="Linkedin" target="_blank" rel="noopener noreferrer"><IoLogoLinkedin/></a></li>
            </ul>
        </div>
    )
}

export default SocialLinks;
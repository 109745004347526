import React, { Component } from 'react';
import SocialLinks from './../components/Social/Social';
import './../styles/About.scss';

class About extends Component {
    componentDidMount(){
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].className += " wrapper--about";
        document.title = 'About Me - albertobonora.com';
    }
    componentWillUnmount() {
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].classList.remove('wrapper--about');
    }
    render() {
        return (
            <div className="condiv about">
                <h1 className="condiv-title">About</h1>

                <div className="contentWrapper">
                    <div className="">
                        <p className="condiv-text">I'm a Web Developer based in Toronto, Canada. With over 10 years of experience in the Media industry I've worked in fast paced environments, live events, tight deadlines and more. Here you'll find some projects that I've built for fun from ideas of my interests (reading, music and sports).</p>

                        <p className="condiv-text">Over the last few years I've worked in an Agile environment and have seen and experienced the benefits and drawbacks to the methodology. But in the end I've realized you need to make Agile work for you and mold it to your team's needs. </p>
                        <p className="condiv-text">Frontend dev is a passion I truly enjoy. Turning mocks/wireframes into live and functional sites is what drives me and keeps me motivated to learn new technologies. Having some design and UX/UI experience, I understand the basics in designing a site that is functional for any user.</p>
                        <p className="condiv-text">Poke around and let me know what your thoughts are on any of the little things I've built.</p>
                        <p className="condiv-text condiv-text--top_spacing">Thanks,<br/>Alberto</p>

                        <SocialLinks/>
                    </div>
                    <div className="about-skills">
                        <p className="condiv-text">Skills:</p>

                        <p className="condiv-text condiv-text--grey">HTML &bull; CSS &bull; LESS &bull; SASS &bull; JS &bull; REACT &bull; VUE &bull; ANGULAR &bull; PHP &bull; WORDPRESS &bull; KENTICO &bull; AEM (Adobe Experience Manager) &bull; PHOTOSHOP &bull; ILLUSTRATOR &bull; VSCODE &bull; GIT &bull; JIRA &bull; CONFLUENCE &bull; UX/UI &bull; AGILE and more</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About
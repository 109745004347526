import React, { Component } from 'react';
import SocialLinks from './../components/Social/Social';
import './../styles/Home.scss';
import ReactGA from 'react-ga';

class Home extends Component {
    componentDidMount(){
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].className += " wrapper--home";
        document.title = 'Alberto Bonora Toronto Based Web Developer - albertobonora.com';
        ReactGA.pageview(window.location.path, '', 'Home');
    }
    componentWillUnmount() {
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].classList.remove('wrapper--home');
    }
    render() {
        return (
            <div className="condiv home">
                <h1 className="home--title"><span className="home--title__light">Alberto</span><span className="home--title__heavy">Bonora</span></h1>
                <h2 className="home--subTitle">Toronto Based Web Developer</h2>
                <SocialLinks />
            </div>
        )
    }
}

export default Home
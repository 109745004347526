import React, { Component } from 'react';
import {DiCss3,DiGithubBadge,DiGoogleAnalytics,DiHtml5,DiJavascript1,DiJira,DiPhotoshop,DiPhp,DiReact,DiSass,DiWordpress,DiVisualstudio,DiLess,DiGoogleCloudPlatform,DiAngularSimple,DiFirebase } from 'react-icons/di';
import {MdOpenInNew} from 'react-icons/md';
import {FaVuejs} from 'react-icons/fa';
import {IoLogoPwa} from 'react-icons/io5';
import './ProjectTile.scss';

class ProjectTile extends Component {
    techIconRender(tech){
        let icon = '';
        switch(tech){
            case 'css':
            icon = <DiCss3 title="CSS"/>
            break;
            case 'angular':
            icon = <DiAngularSimple title="Angular JS"/>
            break;
            case 'github':
            icon = <DiGithubBadge title="Github"/>
            break;
            case 'html':
            icon = <DiHtml5 title="HTML"/>
            break;
            case 'javascript':
            icon = <DiJavascript1 title="Javascript"/>
            break;
            case 'php':
            icon = <DiPhp title="PHP"/>
            break;
            case 'react':
            icon = <DiReact title="React"/>
            break;
            case 'sass':
            icon = <DiSass title="Sass"/>
            break;
            case 'less':
            icon = <DiLess title="Less"/>
            break;
            case 'wordpress':
            icon = <DiWordpress title="Wordpress"/>
            break;
            case 'vue':
            icon = <FaVuejs title="VueJs"/>
            break;
            case 'google api':
            icon = <DiGoogleCloudPlatform title="Google APIs"/>
            break;
            case 'google firebase':
            icon = <DiFirebase title="Google Firebase"/>
            break;
            case 'pwa':
            icon = <IoLogoPwa title="PWA"/>
            break;
            default:
            break;
        }
        return icon;
    }
    render() {
        console.log(this.props.details);
        const isGithub = this.props.details.github;
        return (
            <div className="projectTile">
                <h3 className="projectTile__title">{this.props.details.title}</h3>
                <p className="projectTile__blurb">{this.props.details.blurb}</p>
                <ul className="projectTile__tech-list">{this.props.details.technologies.map((item, index) => (
                    <li className="projectTile__tech-list--item" key={index}>{this.techIconRender(item)}</li>
                    
                ))}</ul>
                <img src={this.props.details.thumbnail} alt={this.props.details.title} title={this.props.details.title} className="projectTile__img"/>
                
                <div className="projectTile__row">
                    {isGithub ? (
                        <a href={this.props.details.github} title="View GitHub In New Tab" target="_blank" rel="noopener noreferrer" className="projectTile__button"><span><DiGithubBadge title="View GitHub In New Tab"/></span>View GitHub</a>
                    ) : (
                        <span></span>
                    )}
                    
                    <a href={this.props.details.url} title="View Demo In New Tab" target="_blank" rel="noopener noreferrer" className="projectTile__button"><span><MdOpenInNew title="View Demo In New Tab"/></span>View Demo</a>
                </div>
            </div>
        )
    }
}

export default ProjectTile
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Header.scss';

const Header = () => {
    let location = useLocation();
    if(location.pathname.replace('/','') !== ''){
        return(
            <header className="siteheader">
                <h1 className="siteheader--title">albertobonora.com</h1>
                {/* <h2 className="siteheader--title">{location.pathname.replace('/','')}</h2> */}
            </header>
        )
    }else{
        return('')
    }
}

export default Header;
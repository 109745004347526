import React, { Component } from 'react';
import SocialLinks from './../components/Social/Social';
import './../styles/Contact.scss';

class Contact extends Component {
    componentDidMount(){
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].className += " wrapper--contact";
        document.title = 'Contact Me - albertobonora.com';
    }
    componentWillUnmount() {
        let innerArray = document.getElementsByClassName('wrapper');
        innerArray[0].classList.remove('wrapper--contact');
    }
    render() {
        return (
            <div className="condiv contact">
                <h1 className="condiv-title">Contact</h1>
                <p className="condiv-text">Thank you for taking the time to check out my site. If you ever want to reach out for any questions or inquiries please feel free to reach me at any of the links below.</p>
                <SocialLinks/>
            </div>
        )
    }
}

export default Contact
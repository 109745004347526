import React, { Component } from 'react';
import Navitem from './../Navitem/Navitem';
import './Nav.scss';

class Navbar extends Component {
    render(){
        return (
            <nav className="navbar">
                <ul className="navbar__list">
                    <Navitem item="Home" icon="home" tolink="/"></Navitem>
                    <Navitem item="About" icon="person" tolink="/about"></Navitem>
                    <Navitem item="Sandbox" icon="beach_access" tolink="/sandbox"></Navitem>
                    <Navitem item="Contact" icon="contact_mail" tolink="/contact"></Navitem>
                </ul>
            </nav>
        )
    }

}

export default Navbar